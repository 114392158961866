import { ThemePublishStatusEnum } from '@/domains/finance-admin/types/partner-details.types';
import { AgreeableItemTypeEnum, AgreementStatusValueEnum } from '@/domains/partnership-details/types/partnership.type';

export type ManagePartnerDetailsState = {
  id: string;
  selectedApp: Record<string, string>;
  agreementDoc: File | null;
  revenueModel: { label: string; value: RevenueModelValuesEnum };
  priceValue: string;
  activationFee: string;
  zidPercentage: string;
  partnerPercentage: string;
  slaps_details: SlapDetails[];
  vatPercentage: string;
  status: AgreementStatusValueEnum;
  taxType: AgreementTaxTypeValueEnum;
  isShippingApp: boolean;
};

export enum RevenueModelEnum {
  FixedPrice = 'Fixed price',
  PercentageOfRevenue = 'Percentage of revenue',
  Slap = 'Slap Model',
}

export enum RevenueModelValuesEnum {
  FixedPrice = 1,
  PercentageOfRevenue = 2,
  Slap = 3,
}

export enum VATEnum {
  Include = 'include',
  Exclude = 'no VAT',
}

export enum VATValuesEnum {
  Include = '1',
  Exclude = '2',
}

export enum ManagePartnerDetailsSelectProperty {
  selectedApp = 'selectedApp',
  revenueModel = 'revenueModel',
}

export enum InputPropertiesEnum {
  priceValue = 'priceValue',
  activationFee = 'activationFee',
  zidPercentage = 'zidPercentage',
  partnerPercentage = 'partnerPercentage',
  vatPercentage = 'vatPercentage',
}

export enum RevenueModelFieldsToShowEnum {
  Empty = 0,
  FixedPrice = 1,
  PercentageOfRevenue = 2,
  Slap = 3,
}

export type PartnershipPartnerType = {
  id: number;
  name: string;
  email: string;
  is_independent: number;
  company_name: string;
  development_store: string;
};

export type AppType = {
  app_id: number;
  partner_id: number;
  app_name: string;
  status: string;
  icon: string;
  type: number;
  contract_status: string;
  contract_doc: string | null;
  pricing_model: number;
  fixed_price: string;
  zid_percentage: string;
  partner_percentage: string;
  vat_percentage: string;
  activation_fees: string;
  slaps_details?: SlapDetails[];
};

export type PartnershipPartnerThemeType = {
  theme_id: number;
  theme_name: string;
  contract_status: string;
  contract_doc: string | null;
  pricing_model: number;
  fixed_price: string;
  zid_percentage: string;
  partner_percentage: string;
  last_upload_contract_date: string | null;
  created_at: string | null;
  publish_status: ThemePublishStatusEnum | '';
};

export type PartnerBankAccountType = {
  partner_name: string | null;
  bank_name: string | null;
  account_name: string | null;
  iban: string | null;
  swift_code: string | null;
  country: string | null;
  city: string | null;
};

export type AgreementDetailsToUpdateType = {
  id: string;
  agreement_status: AgreementStatusValueEnum;
  pricing_model: RevenueModelValuesEnum;
  tax_type: AgreementTaxTypeValueEnum;
  agreement_document?: File;
  fixed_price?: number;
  activation_fees?: number;
  partner_percentage?: number;
  zid_percentage?: number;
  vat_percentage?: number;
  slap_details?: string;
};

export type SlapDetails = {
  app_id: number;
  slap_number: number;
  orders: number;
  price: number;
  slap_key?: string;
  slap_value?: string;
};

export enum EmptyValueEnum {
  Empty = '0.00',
}

export enum FilesLinksState {
  HasLink = 'hasLink',
  NoLink = 'noLink',
}

export enum SlapModelDetails {
  Orders = 'ORDERS',
  Price = 'PRICE',
}

export type AgreeableItemType = {
  item_name: string;
  item_type: AgreeableItemTypeEnum;
  item_id: number;
};

export type AgreementHistoryItemType = {
  id: string;
  item_type: AgreeableItemTypeEnum;
  item_name: string;
  item_id: number;
  version: number;
  document: string | null;
  status: AgreementStatusValueEnum;
  approved_at: string | null;
  is_active: boolean;
  tax_type: number;
  pricing_model: RevenueModelValuesEnum;
  activation_fees: number | null;
  slap_details: SlapDetails[] | null;
  fixed_price: number | null;
  partner_percentage: number | null;
  zid_percentage: number | null;
  vat_percentage: number;
  updated_at: string | null;
  is_shipping_app: boolean;
};

export enum AgreementTaxTypeValueEnum {
  None = 0,
  Vat = 1,
  WithHolding = 2,
}
