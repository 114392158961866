import BaseModel from '../../../api/models/BaseModel';
import { AgreeableItemType } from '@/domains/partnerships-admin/types/partnerDetails.type';

class AgreeableItemsListResponseModel extends BaseModel {
  public agreeable_items: AgreeableItemType[];

  constructor(arg: any) {
    super(arg);
    this.agreeable_items = arg.agreeable_items;
  }
}

export default AgreeableItemsListResponseModel;
