import Vue from 'vue';
import { computed, onBeforeMount, reactive, ref, watch } from '@vue/composition-api';
import useVuelidate from '@vuelidate/core';
import { helpers, minLength, minValue, required } from '@vuelidate/validators';
import {
  AgreeableItemType,
  AgreementDetailsToUpdateType,
  AgreementHistoryItemType,
  AgreementTaxTypeValueEnum,
  EmptyValueEnum,
  InputPropertiesEnum,
  ManagePartnerDetailsSelectProperty,
  ManagePartnerDetailsState,
  RevenueModelEnum,
  RevenueModelFieldsToShowEnum,
  RevenueModelValuesEnum,
  SlapDetails,
  SlapModelDetails,
} from '../../types/partnerDetails.type';
import { REVENUE_MODEL, VAT } from '../dropdownInfo';
import { getAgreementHistory, getPartnerAgreeableItems, updateAgreementDetails } from '../../api/partnerships';
import { isNumberOrFloatingNumber } from '@/helpers/regex/numbers';
import {
  AgreeableItemTypeEnum,
  AgreementStatusValueEnum,
  PartnershipDetailsTabsNamesEnum,
} from '@/domains/partnership-details/types/partnership.type';
import ErrorModel from '@/api/models/ErrorModel';

const useAgreementDetails = (partnerID: number, itemId: number, itemType: number) => {
  const DEFAULT_VAT = '0.15';
  const isLoadingData = ref(false);
  const checkSlaps = ref(false);
  const isContractFileInvalid = ref(false);
  const contractFileLink = ref('');
  const isUpdatingAgreement = ref(false);
  const activeTab = ref(PartnershipDetailsTabsNamesEnum.AgreementDetails);
  const initialAgreementStatus = ref(AgreementStatusValueEnum.InReview);
  const revenueModelFieldsToShow = ref(RevenueModelFieldsToShowEnum.Empty);
  const agreeableItemsList = reactive<Record<string, AgreeableItemType[]>>({ list: [] });
  const itemsSelectionList = reactive<Record<string, Record<string, string>[]>>({ list: [] });
  const agreementHistoryList = reactive<Record<string, AgreementHistoryItemType[]>>({ list: [] });
  const revenueModels = reactive({
    options: REVENUE_MODEL,
  });

  const vatSelect = reactive({
    options: VAT,
  });

  const managePartnerDetailsState = reactive<ManagePartnerDetailsState>({
    id: '',
    selectedApp: {
      label: '',
      value: '',
    },
    agreementDoc: null,
    revenueModel: {
      label: '',
      value: 0,
    },
    priceValue: '',
    activationFee: '',
    zidPercentage: '',
    partnerPercentage: '',
    slaps_details: [defaultSlapData()],
    vatPercentage: '',
    status: AgreementStatusValueEnum.Draft,
    taxType: AgreementTaxTypeValueEnum.Vat,
    isShippingApp: false,
  });

  const validFileSize = helpers.withMessage('Please select a valid PDF or PNG of maximum 5MB', (file: File) => {
    return !file || file.size <= 5000000;
  });

  const validFileType = helpers.withMessage('Please select a valid PNG or PDF file', (file: File) => {
    return !file || file.type === 'application/pdf';
  });

  const rules = {
    id: {},
    selectedApp: {
      label: {},
      value: {},
    },
    agreementDoc: {
      maxSize: validFileSize,
      accepted: validFileType,
    },
    revenueModel: {
      label: {
        required,
      },
      value: {
        required,
      },
    },
    priceValue: {
      priceValue: helpers.withMessage('Price is not valid', (value: string) => {
        return isNumberOrFloatingNumber(value);
      }),
    },
    activationFee: {
      activationFee: helpers.withMessage('Activation fee is not valid', (value: string) => {
        return isNumberOrFloatingNumber(value);
      }),
    },
    zidPercentage: {
      zidPercentage: helpers.withMessage('Percentage is not valid', (value: string) => {
        return isNumberOrFloatingNumber(value) && parseFloat(value) <= 100;
      }),
    },
    partnerPercentage: {
      partnerPercentage: helpers.withMessage('Percentage is not valid', (value: string) => {
        return isNumberOrFloatingNumber(value) && parseFloat(value) <= 100;
      }),
    },
    slaps_details: {
      minLength: minLength(1),
      $each: {
        price: {
          required,
          minValue: minValue(1),
        },
        orders: {
          required,
          minValue: minValue(1),
        },
      },
    },
    vatPercentage: {
      vatPercentage: helpers.withMessage('Percentage is not valid', (value: string) => {
        return isNumberOrFloatingNumber(value) && parseFloat(value) <= 100;
      }),
    },
    status: {},
    taxType: {},
    isShippingApp: {},
  };

  const v$ = useVuelidate(rules, managePartnerDetailsState);

  onBeforeMount(async () => {
    await loadAgreeableItems();
    await loadAgreementHistory();
  });

  watch(
    () => managePartnerDetailsState.zidPercentage,
    (newZidPercentage) => {
      if (v$.value.zidPercentage.$dirty) {
        if (isNaN(parseFloat(newZidPercentage))) managePartnerDetailsState.zidPercentage = '';
        else if (100 - parseFloat(newZidPercentage) !== parseFloat(managePartnerDetailsState.partnerPercentage))
          managePartnerDetailsState.partnerPercentage = `${100 - parseFloat(newZidPercentage)}`;
      }
    },
    { deep: true },
  );

  watch(
    () => managePartnerDetailsState.partnerPercentage,
    (newPartnerPercentage) => {
      if (v$.value.partnerPercentage.$dirty) {
        if (isNaN(parseFloat(newPartnerPercentage))) managePartnerDetailsState.partnerPercentage = '';
        else if (100 - parseFloat(newPartnerPercentage) !== parseFloat(managePartnerDetailsState.zidPercentage))
          managePartnerDetailsState.zidPercentage = `${100 - parseFloat(newPartnerPercentage)}`;
      }
    },
    { deep: true },
  );

  const loadAgreementHistory = async (applicationId = itemId) => {
    isLoadingData.value = true;
    const response = await getAgreementHistory(applicationId, itemType);
    isLoadingData.value = false;
    if (response instanceof ErrorModel || typeof response.agreements === typeof undefined) {
      Vue.$toast.error('Failed to load agreement history');
      return;
    }
    agreementHistoryList.list = response.agreements;
    fillActiveAgreementData();
    showSelectedRevenueFields();
  };

  const getActiveAgreement = () => {
    return agreementHistoryList.list.find((agreement) => agreement.is_active === true);
  };

  const fillActiveAgreementData = () => {
    const activeAgreement = getActiveAgreement();
    if (!activeAgreement) {
      return;
    }
    managePartnerDetailsState.id = activeAgreement.id;
    managePartnerDetailsState.isShippingApp = activeAgreement.is_shipping_app;
    initialAgreementStatus.value = activeAgreement.status;
    switch (activeAgreement.pricing_model) {
      case RevenueModelValuesEnum.FixedPrice:
        managePartnerDetailsState.revenueModel.label = RevenueModelEnum.FixedPrice;
        managePartnerDetailsState.revenueModel.value = RevenueModelValuesEnum.FixedPrice;
        updatePropertyState(InputPropertiesEnum.priceValue, activeAgreement.fixed_price);
        updatePropertyState(InputPropertiesEnum.activationFee, activeAgreement.activation_fees);
        managePartnerDetailsState.slaps_details = [defaultSlapData()];
        managePartnerDetailsState.zidPercentage = '0.00';
        managePartnerDetailsState.partnerPercentage = '0.00';
        break;
      case RevenueModelValuesEnum.PercentageOfRevenue:
        managePartnerDetailsState.revenueModel.label = RevenueModelEnum.PercentageOfRevenue;
        managePartnerDetailsState.revenueModel.value = RevenueModelValuesEnum.PercentageOfRevenue;
        updatePropertyState(InputPropertiesEnum.zidPercentage, activeAgreement.zid_percentage);
        updatePropertyState(InputPropertiesEnum.partnerPercentage, activeAgreement.partner_percentage);
        managePartnerDetailsState.slaps_details = [defaultSlapData()];
        managePartnerDetailsState.priceValue = '0.00';
        managePartnerDetailsState.activationFee = '0.00';
        break;
      case RevenueModelValuesEnum.Slap:
        managePartnerDetailsState.revenueModel.label = RevenueModelEnum.Slap;
        managePartnerDetailsState.revenueModel.value = RevenueModelValuesEnum.Slap;
        managePartnerDetailsState.slaps_details =
          activeAgreement.slap_details && activeAgreement.slap_details.length > 0
            ? JSON.parse(JSON.stringify(activeAgreement.slap_details))
            : [defaultSlapData()];
        updatePropertyState(InputPropertiesEnum.activationFee, activeAgreement.activation_fees);
        managePartnerDetailsState.zidPercentage = '0.00';
        managePartnerDetailsState.partnerPercentage = '0.00';
        managePartnerDetailsState.priceValue = '0.00';
        break;
      default:
        managePartnerDetailsState.revenueModel.label = '';
        managePartnerDetailsState.revenueModel.value = 0;
        break;
    }
    managePartnerDetailsState.status = activeAgreement.status;
    managePartnerDetailsState.taxType = activeAgreement.tax_type ?? AgreementTaxTypeValueEnum.Vat;
    if (managePartnerDetailsState.taxType === AgreementTaxTypeValueEnum.Vat) {
      managePartnerDetailsState.vatPercentage = DEFAULT_VAT;
    }
    updatePropertyState(InputPropertiesEnum.vatPercentage, activeAgreement.vat_percentage);
  };

  const onInputDataChange = (changedValue: Record<string, string>, dataProperty: InputPropertiesEnum) => {
    v$.value[dataProperty].$touch();
    managePartnerDetailsState[dataProperty] = changedValue.value;
  };

  const updatePropertyState = (property: InputPropertiesEnum, value: any) => {
    if (!value) {
      return;
    }
    managePartnerDetailsState[property] = value.toString();
  };

  const slapValidation = (changedValue: Record<string, string>, type: string, slapNumber: number) => {
    const slapObject = managePartnerDetailsState.slaps_details[slapNumber - 1];
    if (type === SlapModelDetails.Orders) {
      slapObject.orders = Number(changedValue.value) ?? 0;
    } else {
      slapObject.price = Number(changedValue.value) ?? 0;
    }
    if (isNaN(Number(changedValue.value)) || Number(changedValue.value) <= 0) {
      checkSlaps.value = true;
      Vue.$toast.error('Please enter greater than zero');
    } else {
      if (managePartnerDetailsState.slaps_details && managePartnerDetailsState.slaps_details.length > 0) {
        checkSlaps.value =
          managePartnerDetailsState.slaps_details.filter((slap) => slap.price === 0 || slap.orders === 0).length > 0;
      } else {
        checkSlaps.value = false;
      }
    }
  };

  const onSelectionDataChange = (
    changedValue: Record<string, string>,
    property: ManagePartnerDetailsSelectProperty,
  ) => {
    v$.value[property].$touch();
    const selectedValue = JSON.parse(JSON.stringify(changedValue));
    managePartnerDetailsState[property].label = selectedValue.label;
    managePartnerDetailsState[property].value = selectedValue.value;
  };

  const onSelectedAppChange = async (changedValue: Record<string, string>, property: 'selectedApp') => {
    managePartnerDetailsState[property] = JSON.parse(JSON.stringify(changedValue));
    await loadAgreementHistory(Number(changedValue.value));
  };

  const onRevenueModelChange = (value: RevenueModelValuesEnum) => {
    v$.value.revenueModel.$touch();
    managePartnerDetailsState.revenueModel.value = value;
    showSelectedRevenueFields();
  };

  const showSelectedRevenueFields = () => {
    switch (managePartnerDetailsState.revenueModel.value) {
      case RevenueModelValuesEnum.FixedPrice:
        revenueModelFieldsToShow.value = RevenueModelFieldsToShowEnum.FixedPrice;
        if (managePartnerDetailsState.zidPercentage === '')
          managePartnerDetailsState.zidPercentage = EmptyValueEnum.Empty;
        if (managePartnerDetailsState.partnerPercentage === '')
          managePartnerDetailsState.partnerPercentage = EmptyValueEnum.Empty;
        break;
      case RevenueModelValuesEnum.PercentageOfRevenue:
        revenueModelFieldsToShow.value = RevenueModelFieldsToShowEnum.PercentageOfRevenue;
        if (managePartnerDetailsState.priceValue === '') managePartnerDetailsState.priceValue = EmptyValueEnum.Empty;
        if (managePartnerDetailsState.activationFee === '')
          managePartnerDetailsState.activationFee = EmptyValueEnum.Empty;
        break;
      case RevenueModelValuesEnum.Slap:
        revenueModelFieldsToShow.value = RevenueModelFieldsToShowEnum.Slap;
        break;

      default:
        revenueModelFieldsToShow.value = RevenueModelFieldsToShowEnum.Empty;
        break;
    }
  };

  const onAgreementDocSelect = (file: File[]) => {
    if (!file.length) {
      return;
    }
    managePartnerDetailsState.agreementDoc = file[0];
    v$.value.agreementDoc.$touch();
  };

  const onAgreementDocRemove = () => {
    managePartnerDetailsState.agreementDoc = null;
    v$.value.agreementDoc.$touch();
  };

  const disableSubmitUpdate = computed(() => {
    let hasInvalidValue = false;
    switch (Number(managePartnerDetailsState.revenueModel.value)) {
      case RevenueModelValuesEnum.FixedPrice:
        if (!managePartnerDetailsState.priceValue) {
          hasInvalidValue = true;
        }
        break;
      case RevenueModelValuesEnum.PercentageOfRevenue:
        if (!managePartnerDetailsState.zidPercentage || !managePartnerDetailsState.partnerPercentage) {
          hasInvalidValue = true;
        }
        break;
      case RevenueModelValuesEnum.Slap:
        hasInvalidValue =
          managePartnerDetailsState.slaps_details.filter((slap) => slap.price === 0 || slap.orders === 0).length > 0;
        break;
      default:
        break;
    }
    if (
      managePartnerDetailsState.taxType !== AgreementTaxTypeValueEnum.None &&
      Number(managePartnerDetailsState.vatPercentage) <= 0
    ) {
      hasInvalidValue = true;
    }
    return v$.value.$invalid || hasInvalidValue;
  });

  const disableStatusField = computed(
    () =>
      initialAgreementStatus.value === AgreementStatusValueEnum.Draft ||
      initialAgreementStatus.value === AgreementStatusValueEnum.Approved,
  );

  function updateSlapsData() {
    if (managePartnerDetailsState.revenueModel.value === RevenueModelValuesEnum.Slap) {
      const slapData: SlapDetails[] = [];
      managePartnerDetailsState.slaps_details.map((slap, index) => {
        slapData.push(getSlapData(SlapModelDetails.Orders, slap.orders, slap, index));
        slapData.push(getSlapData(SlapModelDetails.Price, slap.price, slap, index));
      });
      return slapData;
    }
  }

  function getSlapData(key: string, value: number, slap: SlapDetails, index: number) {
    const slabObject = {} as SlapDetails;
    slabObject.app_id = Number(managePartnerDetailsState.selectedApp.value);
    slabObject.slap_number = index + 1;
    slabObject.slap_key = key;
    slabObject.slap_value = value.toString();
    return slabObject;
  }

  const onUpdateAgreementDetails = async () => {
    isUpdatingAgreement.value = true;
    const agreementDetails: AgreementDetailsToUpdateType = {
      id: managePartnerDetailsState.id,
      agreement_status: managePartnerDetailsState.status,
      pricing_model: Number(managePartnerDetailsState.revenueModel.value),
      tax_type: managePartnerDetailsState.taxType,
      agreement_document: managePartnerDetailsState.agreementDoc ?? undefined,
      fixed_price: Number(managePartnerDetailsState.priceValue),
      activation_fees: Number(managePartnerDetailsState.activationFee),
      zid_percentage: Number(managePartnerDetailsState.zidPercentage),
      partner_percentage: Number(managePartnerDetailsState.partnerPercentage),
      vat_percentage: Number(managePartnerDetailsState.vatPercentage),
      slap_details: JSON.stringify(updateSlapsData()),
    };
    const response = await updateAgreementDetails(agreementDetails);
    isUpdatingAgreement.value = false;
    if (response instanceof ErrorModel || typeof response.agreement === typeof undefined) {
      Vue.$toast.error('Error updating Partnership Data');
      return;
    }
    Vue.$toast.success('Updated Partnership Data Successfully');
    await loadAgreementHistory(Number(managePartnerDetailsState.selectedApp.value));
  };

  const onCancelClicked = () => {
    resetEntry();
  };

  const onAddSlapDetails = () => {
    if (managePartnerDetailsState.slaps_details && managePartnerDetailsState.slaps_details.length < 6) {
      managePartnerDetailsState.slaps_details?.push({
        app_id: Number(managePartnerDetailsState.selectedApp.value),
        slap_number: managePartnerDetailsState.slaps_details.length + 1,
        orders: 0,
        price: 0,
      });
    } else {
      Vue.$toast.error('Maximum of 6 slaps are allowed');
    }
  };

  const onDeleteSlapDetails = (slapNumber: number) => {
    if (managePartnerDetailsState.slaps_details && managePartnerDetailsState.slaps_details.length > 1) {
      managePartnerDetailsState.slaps_details?.splice(slapNumber - 1, 1);
    } else {
      Vue.$toast.error('Minimum of 1 slap is required');
    }
  };

  function defaultSlapData() {
    const slabObject = {} as SlapDetails;
    slabObject.app_id = -1;
    slabObject.slap_number = 1;
    slabObject.orders = 0;
    slabObject.price = 0;
    return slabObject;
  }

  const resetEntry = () => {
    managePartnerDetailsState.agreementDoc = null;
    managePartnerDetailsState.revenueModel = {
      label: '',
      value: 0,
    };
    managePartnerDetailsState.priceValue = '';
    managePartnerDetailsState.activationFee = '';
    managePartnerDetailsState.zidPercentage = '';
    managePartnerDetailsState.partnerPercentage = '';
    managePartnerDetailsState.slaps_details = [defaultSlapData()];
    managePartnerDetailsState.vatPercentage = '';
  };

  const loadAgreeableItems = async () => {
    isLoadingData.value = true;
    const response = await getPartnerAgreeableItems(partnerID, itemType);
    isLoadingData.value = false;
    if (response instanceof ErrorModel || typeof response.agreeable_items === typeof undefined) {
      Vue.$toast.error('Failed to load applications list');
      return;
    }
    agreeableItemsList.list = response.agreeable_items;
    itemsSelectionList.list = agreeableItemsList.list.map((app: AgreeableItemType) => ({
      label: app.item_name,
      value: `${app.item_id}`,
    }));
    managePartnerDetailsState.selectedApp = itemsSelectionList.list.find((app) => Number(app.value) === itemId) || {
      label: '',
      value: '',
    };
  };
  const onTaxTypeClick = (taxType: AgreementTaxTypeValueEnum) => {
    managePartnerDetailsState.taxType = taxType;
    if (managePartnerDetailsState.taxType === AgreementTaxTypeValueEnum.None) {
      managePartnerDetailsState.vatPercentage = '0';
    }
    if (
      managePartnerDetailsState.taxType === AgreementTaxTypeValueEnum.Vat &&
      !Number(managePartnerDetailsState.vatPercentage)
    ) {
      managePartnerDetailsState.vatPercentage = DEFAULT_VAT;
    }
  };

  return {
    itemsSelectionList,
    isLoadingData,
    managePartnerDetailsState,
    v$,
    agreeableItemsList,
    isContractFileInvalid,
    contractFileLink,
    revenueModels,
    vatSelect,
    revenueModelFieldsToShow,
    RevenueModelFieldsToShowEnum,
    disableSubmitUpdate,
    checkSlaps,
    SlapModelDetails,
    RevenueModelValuesEnum,
    ManagePartnerDetailsSelectProperty,
    agreementHistoryList,
    initialAgreementStatus,
    AgreementStatusValueEnum,
    AgreementTaxTypeValueEnum,
    isUpdatingAgreement,
    InputPropertiesEnum,
    AgreeableItemTypeEnum,
    activeTab,
    PartnershipDetailsTabsNamesEnum,
    disableStatusField,
    onSelectionDataChange,
    onInputDataChange,
    onSelectedAppChange,
    onUpdateAgreementDetails,
    onCancelClicked,
    onAddSlapDetails,
    onDeleteSlapDetails,
    slapValidation,
    onRevenueModelChange,
    onTaxTypeClick,
    onAgreementDocSelect,
    onAgreementDocRemove,
  };
};

export default useAgreementDetails;
