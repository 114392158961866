import BaseModel from '../../../api/models/BaseModel';
import { PartnershipAgreementItemType } from '../types/managePartnerships.type';
import { LengthAwarePaginationInterface } from '@/types/global-types/pagination.type';

class AppAgreementsListResponseModel extends BaseModel {
  public agreements: PartnershipAgreementItemType[];
  public pagination: LengthAwarePaginationInterface;

  constructor(arg: any) {
    super(arg);
    this.agreements = arg.agreements;
    this.pagination = arg.pagination;
  }
}

export default AppAgreementsListResponseModel;
