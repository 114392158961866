import BaseModel from '../../../api/models/BaseModel';
import { AgreementHistoryItemType } from '@/domains/partnerships-admin/types/partnerDetails.type';

class AgreementUpdateResponseModel extends BaseModel {
  public agreement: AgreementHistoryItemType;

  constructor(arg: any) {
    super(arg);
    this.agreement = arg.agreement;
  }
}

export default AgreementUpdateResponseModel;
