

















































import { defineComponent, onBeforeMount, reactive, ref } from '@vue/composition-api';
import { ZidInput, ZidLoader } from '@zidsa/ui';
import { PartnerBankAccountType } from '../../../../types/partnerDetails.type';
import BaseText from '@/components/text/BaseText.vue';
import { getPartnershipDetailsInfo } from '@/domains/partnerships-admin/api/partnerships';
import ErrorModel from '@/api/models/ErrorModel';
import Vue from 'vue';

export default defineComponent({
  props: ['partnershipId'],
  components: {
    ZidInput,
    BaseText,
    ZidLoader,
  },
  setup(props) {
    const isLoadingTab = ref(false);
    const bankAccInfo = reactive<PartnerBankAccountType>({
      partner_name: null,
      bank_name: null,
      account_name: null,
      iban: null,
      swift_code: null,
      country: null,
      city: null,
    });
    onBeforeMount(async () => {
      isLoadingTab.value = true;
      const response = await getPartnershipDetailsInfo(props.partnershipId);
      isLoadingTab.value = false;
      if (response instanceof ErrorModel) {
        Vue.$toast.error('Failed to load partnership info');
        return;
      }
      if (!response.bank_account) {
        return;
      }
      bankAccInfo.bank_name = response.bank_account.bank_name;
      bankAccInfo.account_name = response.bank_account.account_name;
      bankAccInfo.iban = response.bank_account.iban;
      bankAccInfo.swift_code = response.bank_account.swift_code;
      bankAccInfo.country = response.bank_account.country;
      bankAccInfo.city = response.bank_account.city;
    });
    return { isLoadingTab, bankAccInfo };
  },
});
