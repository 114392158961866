import { AgreementStatusValueEnum } from '../../partnership-details/types/partnership.type';
import { RevenueModelValuesEnum } from '@/domains/partnerships-admin/types/partnerDetails.type';

export type ManagePartnershipsState = {
  selectedResultPerPage: Record<string, string>;
  searchValue: string;
  agreementStatus: Record<string, string>;
  targetPage: number;
};

export interface ManagePartnershipFiltersParamsInterface {
  search: string;
  agreement_status: number[];
  per_page: number;
  page: number;
}

export type SortingStateType = {
  partnerName: null | boolean;
  partnershipSubmissionDate: null | boolean;
  applicationName: null | boolean;
  applicationStatus: null | boolean;
  applicationPublishDate: null | boolean;
  partnerShare: null | boolean;
  zidShare: null | boolean;
  contractStatus: null | boolean;
  contractUpdateDate: null | boolean;
  mobileNumber: null | boolean;
};

export type PartnershipsListType = {
  list: PartnershipAgreementItemType[];
};

export enum ManagePartnershipsInputProperty {
  searchValue = 'searchValue',
}

export enum ManagePartnershipsSelectProperty {
  selectedResultPerPage = 'selectedResultPerPage',
  agreementStatus = 'agreementStatus',
}

export enum ContractStatusEnum {
  Unsigned = 'UNSIGNED',
  Signed = 'SIGNED',
  Pending = 'PENDING',
  Rejected = 'REJECTED',
}

export type PartnershipAgreementItemType = {
  id: string;
  partner_id: number;
  app_id: number;
  app_name: string;
  app_name_ar: string;
  updated_at: string | null;
  submission_date: string | null;
  agreement_status: AgreementStatusValueEnum;
  mobile_number: string;
  partner_name: string;
  revenue_model: RevenueModelValuesEnum;
  partnership_id: number;
};

export enum ThemeApprovalStatusLabelsEnum {
  All = 'All',
  Pending = 'Pending',
  Rejected = 'Rejected',
  Accepted = 'Accepted',
  Draft = 'Draft',
}

export enum ThemeApprovalStatusValuesEnum {
  All = '',
  Rejected = 0,
  Pending = 1,
  Accepted = 2,
  Draft = 3,
}
export enum ThemePublishingStatusLabelsEnum {
  All = 'All',
  Draft = 'Draft',
  Published = 'Published',
}

export enum ThemePublishingStatusValuesEnum {
  All = '',
  Draft = 'draft',
  Published = 'published',
}

export enum ThemeContractStatusAllValueEnum {
  All = '',
}

export enum ThemeContractStatusLabelsEnum {
  Unsigned = 'Unsigned',
  Signed = 'Signed',
  Pending = 'Pending',
  Rejected = 'Rejected',
  All = 'All',
}

export type ThemePartnershipAgreementItemType = {
  id: string;
  partner_id: number;
  theme_id: number;
  theme_name: string;
  theme_name_ar: string;
  updated_at: string | null;
  submission_date: string | null;
  agreement_status: AgreementStatusValueEnum;
  mobile_number: string;
  partner_name: string;
  revenue_model: RevenueModelValuesEnum;
  partnership_id: number;
};

export type ThemePartnershipsListType = {
  list: ThemePartnershipAgreementItemType[];
};
