import { defineComponent } from '@vue/composition-api';
import BaseText from '@/components/text/BaseText.vue';
import {
  ZidBadge,
  ZidButton,
  ZidCheckbox,
  ZidCol,
  ZidFileSelector,
  ZidInput,
  ZidLoader,
  ZidRadio,
  ZidRow,
  ZidSelect,
  ZidSelectBody,
  ZidSelectHeader,
  ZidSelectOption,
  ZidTable,
  ZidTableCell,
  ZidTableHeader,
  ZidTableRow,
  ZidTableRowGroup,
} from '@zidsa/ui';
import useAgreementDetails from '../../../../helpers/partner-details/useAgreementDetails';
import BankInformation from '../bank-information/BankInformation.vue';
import EditIcon from '@/assets/icons/applications/EditIcon.vue';
import BaseIcon from '@/components/base-icon/BaseIcon.vue';
import ContactInformation from '../contact-information/ContactInformation.vue';
import BusinessDetails from '../business-details/BusinessDetails.vue';
import TabButton from '@/domains/partnership-details/views/partnership-details/components/TabButton.vue';

export default defineComponent({
  props: ['appID', 'partnerID', 'partnerName', 'itemType', 'partnershipId'],
  components: {
    ContactInformation,
    BusinessDetails,
    BaseIcon,
    EditIcon,
    BaseText,
    ZidSelect,
    ZidSelectHeader,
    ZidSelectBody,
    ZidSelectOption,
    ZidCheckbox,
    ZidButton,
    ZidInput,
    ZidLoader,
    ZidRow,
    ZidCol,
    ZidRadio,
    ZidFileSelector,
    ZidTable,
    ZidTableRowGroup,
    ZidTableRow,
    ZidTableCell,
    ZidTableHeader,
    BankInformation,
    ZidBadge,
    TabButton,
  },
  setup(props) {
    return {
      ...useAgreementDetails(props.partnerID, props.appID, props.itemType),
    };
  },
});
