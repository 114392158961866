import { RevenueModelEnum, RevenueModelValuesEnum, VATEnum, VATValuesEnum } from '../types/partnerDetails.type';
import { AgreementStatusValueEnum } from '@/domains/partnership-details/types/partnership.type';

export const AGREEMENT_STATUS_DROPDOWN = [
  { label: 'All', value: '' },
  { label: 'Draft', value: AgreementStatusValueEnum.Draft },
  { label: 'In Review', value: AgreementStatusValueEnum.InReview },
  { label: 'Signed', value: AgreementStatusValueEnum.Approved },
  { label: 'Rejected', value: AgreementStatusValueEnum.Rejected },
];

export const REVENUE_MODEL = [
  { label: RevenueModelEnum.FixedPrice, value: RevenueModelValuesEnum.FixedPrice },
  { label: RevenueModelEnum.PercentageOfRevenue, value: RevenueModelValuesEnum.PercentageOfRevenue },
  { label: RevenueModelEnum.Slap, value: RevenueModelValuesEnum.Slap },
];

export const VAT = [
  { label: VATEnum.Include, value: VATValuesEnum.Include },
  { label: VATEnum.Exclude, value: VATValuesEnum.Exclude },
];
