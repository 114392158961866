import { onBeforeMount, reactive, ref, watch } from '@vue/composition-api';
import useVuelidate from '@vuelidate/core';
import dayjs from 'dayjs';
import {
  ManagePartnershipsInputProperty,
  ManagePartnershipsSelectProperty,
  ManagePartnershipsState,
  PartnershipAgreementItemType,
  PartnershipsListType,
} from '../types/managePartnerships.type';
import { AGREEMENT_STATUS_DROPDOWN } from './dropdownInfo';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import { useActions } from 'vuex-composition-helpers';
import { getAppAgreementsList } from '@/domains/partnerships-admin/api/partnerships';
import ErrorModel from '@/api/models/ErrorModel';
import Vue from 'vue';
import * as XLSX from 'xlsx';
import router from '@/router';
import { RoutingRouteEnum } from '@/router/routes.enum';
import {
  AgreementStatusLabelEnum,
  AgreementStatusValueEnum,
} from '@/domains/partnership-details/types/partnership.type';
import { RevenueModelEnum, RevenueModelValuesEnum } from '@/domains/partnerships-admin/types/partnerDetails.type';

dayjs.extend(customParseFormat);

const useManagePartnerships = () => {
  const RESULT_PER_PAGE = [];
  for (let i = 30; i <= 100; i += 10) {
    RESULT_PER_PAGE.push({
      label: `${i}`,
      value: `${i}`,
    });
  }
  const { setIsAppLoading } = useActions(['setIsAppLoading']);
  const isLoading = ref(false);
  const totalPages = ref(0);
  const totalResults = ref(0);
  const managePartnershipsState = reactive<ManagePartnershipsState>({
    selectedResultPerPage: {
      label: '30',
      value: '30',
    },
    targetPage: 1,
    searchValue: '',
    agreementStatus: {
      label: 'In Review',
      value: AgreementStatusValueEnum.InReview.toString(),
    },
  });

  const resultPerPage = reactive({
    options: RESULT_PER_PAGE,
  });

  const agreementStatusList = reactive({
    options: AGREEMENT_STATUS_DROPDOWN,
  });

  const agreementsList = reactive<PartnershipsListType>({
    list: [],
  });

  const rules = {
    selectedResultPerPage: {
      label: {},
      value: {},
    },
    searchValue: {},
    agreementStatus: {
      label: {},
      value: {},
    },
    targetPage: {},
  };
  const v$ = useVuelidate(rules, managePartnershipsState);

  const onInputDataChange = (changedValue: string, dataProperty: ManagePartnershipsInputProperty) => {
    v$.value[dataProperty].$touch();
    managePartnershipsState[dataProperty] = changedValue;
    managePartnershipsState.targetPage = 1;
  };

  const onSelectionDataChange = (changedValue: Record<string, string>, property: ManagePartnershipsSelectProperty) => {
    v$.value[property].$touch();
    const selectedValue = JSON.parse(JSON.stringify(changedValue));
    managePartnershipsState[property].label = selectedValue.label;
    managePartnershipsState[property].value = selectedValue.value;
    managePartnershipsState.targetPage = 1;
  };

  onBeforeMount(async () => {
    setIsAppLoading(false);
    await loadAppAgreementsList();
  });

  watch(managePartnershipsState, async () => {
    await loadAppAgreementsList();
  });

  const loadAppAgreementsList = async () => {
    isLoading.value = true;
    const response = await getAppAgreementsList({
      search: managePartnershipsState.searchValue,
      agreement_status: managePartnershipsState.agreementStatus.value
        ? [Number(managePartnershipsState.agreementStatus.value)]
        : [],
      per_page: Number(managePartnershipsState.selectedResultPerPage.value),
      page: managePartnershipsState.targetPage,
    });
    isLoading.value = false;
    if (response instanceof ErrorModel || typeof response.agreements === typeof undefined) {
      Vue.$toast.error('Failed to load app agreements list.');
      return;
    }
    agreementsList.list = response.agreements;
    totalPages.value = response.pagination.last_page;
    totalResults.value = response.pagination.total;
  };

  const onExportAllClicked = () => {
    const dataToBeExported: PartnershipAgreementItemType[] = agreementsList.list;
    const worksheet = XLSX.utils.json_to_sheet(dataToBeExported);
    const new_workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(new_workbook, worksheet, 'partnerships');
    XLSX.writeFile(new_workbook, `all-partnerships-${dayjs().format('DD-MM-YYYY')}.xlsx`);
  };

  const onEditClicked = (agreement: PartnershipAgreementItemType) => {
    router
      .push({
        name: RoutingRouteEnum.ManagePartnerships_Partner,
        params: {
          partnerName: agreement.partner_name,
          partnerID: `${agreement.partner_id}`,
          appID: `${agreement.app_id}`,
          partnershipID: `${agreement.partnership_id}`,
        },
      })
      .catch((err) => {
        /**/
      });
  };

  const getAgreementStatusLabel = (status: AgreementStatusValueEnum): AgreementStatusLabelEnum => {
    switch (status) {
      case AgreementStatusValueEnum.Draft:
        return AgreementStatusLabelEnum.Draft;
      case AgreementStatusValueEnum.InReview:
        return AgreementStatusLabelEnum.InReview;
      case AgreementStatusValueEnum.Approved:
        return AgreementStatusLabelEnum.Approved;
      case AgreementStatusValueEnum.Rejected:
        return AgreementStatusLabelEnum.Rejected;
    }
  };

  const getStatusBadgeType = (status: AgreementStatusValueEnum): string => {
    switch (status) {
      case AgreementStatusValueEnum.Draft:
        return 'primary';
      case AgreementStatusValueEnum.InReview:
        return 'warning';
      case AgreementStatusValueEnum.Approved:
        return 'success';
      case AgreementStatusValueEnum.Rejected:
        return 'danger';
    }
  };

  const getRevenueModelLabel = (revenueModel: RevenueModelValuesEnum): RevenueModelEnum => {
    switch (revenueModel) {
      case RevenueModelValuesEnum.PercentageOfRevenue:
        return RevenueModelEnum.PercentageOfRevenue;
      case RevenueModelValuesEnum.FixedPrice:
        return RevenueModelEnum.FixedPrice;
      case RevenueModelValuesEnum.Slap:
        return RevenueModelEnum.Slap;
    }
  };

  return {
    isLoading,
    managePartnershipsState,
    resultPerPage,
    v$,
    agreementStatusList,
    agreementsList,
    ManagePartnershipsInputProperty,
    ManagePartnershipsSelectProperty,
    totalPages,
    totalResults,
    onInputDataChange,
    onSelectionDataChange,
    onEditClicked,
    onExportAllClicked,
    getAgreementStatusLabel,
    getStatusBadgeType,
    getRevenueModelLabel,
  };
};

export default useManagePartnerships;
