import { render, staticRenderFns } from "./ManagePartnershipsThemes.vue?vue&type=template&id=4a47f156&scoped=true&"
import script from "./mangePartnershipsThemes.ts?vue&type=script&lang=ts&"
export * from "./mangePartnershipsThemes.ts?vue&type=script&lang=ts&"
import style0 from "./manage-partnerships-themes.scss?vue&type=style&index=0&id=4a47f156&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4a47f156",
  null
  
)

export default component.exports