<template>
  <router-view />
</template>

<script>
import { defineComponent } from '@vue/composition-api';
import { ZidLoader } from '@zidsa/ui';

export default defineComponent({
  components: {
    ZidLoader,
  },
  setup() {
    return {};
  },
});
</script>
