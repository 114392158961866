import httpClient from '@/api/httpClient';
import AppAgreementsListResponseModel from '../models/AppAgreementsListResponseModel';
import { AgreementDetailsToUpdateType, RevenueModelValuesEnum } from '../types/partnerDetails.type';
import ThemeAgreementsListResponseModel from '@/domains/partnerships-admin/models/ThemeAgreementsListResponseModel';
import ErrorModel from '@/api/models/ErrorModel';
import { ManagePartnershipFiltersParamsInterface } from '@/domains/partnerships-admin/types/managePartnerships.type';
import {
  AgreeableItemTypeEnum,
  PartnershipBusinessDetailsInterface,
  PartnershipContactInfoState,
} from '@/domains/partnership-details/types/partnership.type';
import AgreeableItemsListResponseModel from '@/domains/partnerships-admin/models/AgreeableItemsListResponseModel';
import AgreementHistoryResponseModel from '@/domains/partnerships-admin/models/AgreementHistroyResponseModel';
import AgreementUpdateResponseModel from '@/domains/partnerships-admin/models/AgreementUpdateResponseModel';
import PartnershipResponseModel from '@/domains/partnership-details/models/PartnershipResponseModel';
import BaseModel from '@/api/models/BaseModel';
import { buildBusinessDetailsFormData } from '@/domains/partnership-details/helpers/formHelpers';

const ENDPOINT_URL = '/partners-admin/partnership';

export const getAppAgreementsList = async (
  params: ManagePartnershipFiltersParamsInterface,
): Promise<AppAgreementsListResponseModel | ErrorModel> => {
  return httpClient
    .get(`${ENDPOINT_URL}/app-agreements`, { params: { ...params } })
    .then((response) => new AppAgreementsListResponseModel(response))
    .catch((error) => error);
};

export const getThemeAgreementsList = async (
  params: ManagePartnershipFiltersParamsInterface,
): Promise<ThemeAgreementsListResponseModel | ErrorModel> => {
  return httpClient
    .get(`${ENDPOINT_URL}/theme-agreements`, { params: { ...params } })
    .then((response) => new ThemeAgreementsListResponseModel(response))
    .catch((error) => error);
};

export const getPartnerAgreeableItems = async (
  partnerId: number,
  type: AgreeableItemTypeEnum,
): Promise<AgreeableItemsListResponseModel | ErrorModel> => {
  return httpClient
    .get(`${ENDPOINT_URL}/agreements/agreeable-items/${partnerId}`, { params: { item_type: type } })
    .then((response) => new AgreeableItemsListResponseModel(response))
    .catch((error) => error);
};

export const getAgreementHistory = async (
  itemId: number,
  type: AgreeableItemTypeEnum,
): Promise<AgreementHistoryResponseModel | ErrorModel> => {
  return httpClient
    .get(`${ENDPOINT_URL}/agreement-history`, { params: { item_id: itemId, item_type: type } })
    .then((response) => new AgreementHistoryResponseModel(response))
    .catch((error) => error);
};

export const updateAgreementDetails = async (
  agreementDetails: AgreementDetailsToUpdateType,
): Promise<AgreementUpdateResponseModel | ErrorModel> => {
  const agreementData = new FormData();
  agreementData.append('id', agreementDetails.id);
  agreementData.append('pricing_model', agreementDetails.pricing_model.toString());
  agreementData.append('agreement_status', agreementDetails.agreement_status.toString());
  agreementData.append('tax_type', agreementDetails.tax_type.toString());

  if (agreementDetails.agreement_document) {
    agreementData.append('agreement_document', agreementDetails.agreement_document);
  }

  if (agreementDetails.vat_percentage) {
    agreementData.append('vat_percentage', agreementDetails.vat_percentage.toString());
  }

  switch (agreementDetails.pricing_model) {
    case RevenueModelValuesEnum.FixedPrice:
      agreementData.append('fixed_price', agreementDetails.fixed_price?.toString() ?? '');
      agreementData.append('activation_fees', agreementDetails.activation_fees?.toString() ?? '');
      break;
    case RevenueModelValuesEnum.PercentageOfRevenue:
      agreementData.append('zid_percentage', agreementDetails.zid_percentage?.toString() ?? '');
      agreementData.append('partner_percentage', agreementDetails.partner_percentage?.toString() ?? '');
      break;
    case RevenueModelValuesEnum.Slap:
      agreementData.append('slap_details', agreementDetails.slap_details ?? '');
      agreementData.append('activation_fees', agreementDetails.activation_fees?.toString() ?? '');
      break;
    default:
      break;
  }

  return httpClient
    .post(`${ENDPOINT_URL}/agreements`, agreementData)
    .then((response) => new AgreementUpdateResponseModel(response))
    .catch((error) => error);
};

export const getPartnershipDetailsInfo = async (id: number): Promise<PartnershipResponseModel | ErrorModel> => {
  return httpClient
    .get(`${ENDPOINT_URL}/${id}`)
    .then((response) => new PartnershipResponseModel(response))
    .catch((error) => error);
};

export const updatePartnershipContactInfo = async (
  partnershipId: number,
  data: PartnershipContactInfoState,
): Promise<BaseModel | ErrorModel> => {
  return httpClient
    .post(`${ENDPOINT_URL}`, {
      id: partnershipId,
      step: 2,
      partnership_officer_name_ar: data.partnershipOfficerNameAr,
      partnership_officer_name_en: data.partnershipOfficerNameEn,
      partnership_officer_email: data.partnershipOfficerEmail,
      partnership_officer_mobile: data.partnershipOfficerMobile,
      with_financial_officer: data.withFinancialOfficer,
      financial_officer_name_ar: data.financialOfficerNameAr ?? null,
      financial_officer_name_en: data.financialOfficerNameEn ?? null,
      financial_officer_email: data.financialOfficerEmail ?? null,
      financial_officer_mobile: data.financialOfficerMobile ?? null,
    })
    .then((response) => new BaseModel(response))
    .catch((error) => error);
};

export const updatePartnershipBusinessDetails = async (
  partnershipId: number,
  data: PartnershipBusinessDetailsInterface,
): Promise<BaseModel | ErrorModel> => {
  const businessDetailsData = buildBusinessDetailsFormData(data);
  businessDetailsData.append('id', partnershipId.toString());
  businessDetailsData.append('step', '3');
  return httpClient
    .post(`${ENDPOINT_URL}`, businessDetailsData)
    .then((response) => new BaseModel(response))
    .catch((error) => error);
};
