import { defineComponent } from '@vue/composition-api';
import BaseText from '@/components/text/BaseText.vue';
import BaseIcon from '@/components/base-icon/BaseIcon.vue';
import {
  ZidInput,
  ZidSelect,
  ZidSelectHeader,
  ZidSelectBody,
  ZidSelectOption,
  ZidLoader,
  ZidButton,
  ZidTable,
  ZidTableRowGroup,
  ZidTableHeader,
  ZidTableRow,
  ZidTableCell,
  ZidPageHeader,
  ZidBreadcrumb,
  ZidBreadcrumbItem,
  ZidPagination,
  ZidInputGroup,
  ZidInputTrailing,
  ZidBadge,
} from '@zidsa/ui';
import ExportIcon from '@/assets/icons/domains/partnership-admin/ExportIcon.vue';
import SearchIcon from '@/assets/icons/applications/SearchIcon.vue';
import EditIcon from '@/assets/icons/applications/EditIcon.vue';
import useManagePartnerships from '../helpers/useManagePartnerships';

export default defineComponent({
  components: {
    BaseText,
    BaseIcon,
    ZidInput,
    ZidSelect,
    ZidSelectHeader,
    ZidSelectBody,
    ZidSelectOption,
    ExportIcon,
    SearchIcon,
    EditIcon,
    ZidLoader,
    ZidButton,
    ZidTable,
    ZidTableRowGroup,
    ZidTableHeader,
    ZidTableRow,
    ZidTableCell,
    ZidPageHeader,
    ZidBreadcrumb,
    ZidBreadcrumbItem,
    ZidPagination,
    ZidInputGroup,
    ZidInputTrailing,
    ZidBadge,
  },
  setup() {
    return {
      ...useManagePartnerships(),
    };
  },
});
