import BaseModel from '../../../api/models/BaseModel';
import { AgreementHistoryItemType } from '@/domains/partnerships-admin/types/partnerDetails.type';

class AgreementsHistoryResponseModel extends BaseModel {
  public agreements: AgreementHistoryItemType[];

  constructor(arg: any) {
    super(arg);
    this.agreements = arg.agreements;
  }
}

export default AgreementsHistoryResponseModel;
