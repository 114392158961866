






























import { defineComponent, ref } from '@vue/composition-api';
import { ZidPageHeader, ZidBreadcrumb, ZidBreadcrumbItem } from '@zidsa/ui';
import AgreementDetails from './agreement-details/AgreementDetails.vue';
import { AgreeableItemTypeEnum } from '@/domains/partnership-details/types/partnership.type';
export default defineComponent({
  components: {
    AgreementDetails,
    ZidPageHeader,
    ZidBreadcrumb,
    ZidBreadcrumbItem,
  },
  setup(_, context) {
    const partnerName = ref(context.root.$route.params.partnerName ?? '');
    const partnerID = ref(Number(context.root.$route.params.partnerID));
    const appID = ref(Number(context.root.$route.params.appID));
    const themeId = ref(Number(context.root.$route.params.themeId));
    const partnershipID = ref(Number(context.root.$route.params.partnershipID));
    const isLoadingData = ref(false);
    return {
      partnerName,
      partnerID,
      appID,
      themeId,
      partnershipID,
      isLoadingData,
      AgreeableItemTypeEnum,
    };
  },
});
